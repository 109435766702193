import { Box, Container, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { LinkNormal } from "modules/picasso-ui/link/LinkNormal";
import { LandingSecondarySectionTitle } from "../../TypographyLanding";
import { LandingFaqConfig } from "./LandingFaq.types";
import { useId } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "next-i18next";

export type LandingFaqLightProps = {
    title?: string
    titleNoMarginBottom?: boolean
    useLandingTitle?: boolean
    items: any[]
    config?: LandingFaqConfig
    styles: LandingFaqPropsCss
    supportContact?: string
    containerDisabled?: boolean
}

export type LandingFaqPropsCss = {
    root?: any
    title?: any
    rowHeading: any
    rowContent?: any
}

const styleDef = {
    root: theme=>({
        position: 'relative',
        '& label': {
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& label svg': {
            fill: '#646e8e!important',
        },
        '& input': {
            visibility: 'hidden',
            height: '1px',
            overflow: 'hidden',
            position: 'absolute',
        },
        '& input ~ div': {
            display: 'none',
        },
        '& input:checked ~ div': {
            display: 'block',
        },
        '& input:checked ~ label svg': {
            transform: 'rotate(180deg)',
            transition: '300ms ease all',
        },
    })
}

const getSiblingByTagName = (elem, tagname) => {
    let els = elem.parentNode.children;
    for (var i=0; i < els.length; i++) {
        if (els[i].tagName.toLowerCase() == tagname) {
            return els[i]
        }
    }
    return null
}

export const LandingFaqLight = ({title, items, config, styles, supportContact, titleNoMarginBottom, containerDisabled, useLandingTitle = true}:LandingFaqLightProps) => {

    const { t } = useTranslation('yoio')

    const elemId = 'faq-'+useId().replace(new RegExp(':', 'g'), '--');

    const rowSx = theme=>({
        '&+&': {
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        '& div': {
            paddingBottom: theme.spacing(3)
        }
    })

    const rootStyle = [styleDef.root, styles?.root]
    const rowHeadingCss = [theme=>({fontWeight: 500, fontSize: '18px', padding: `${theme.spacing(3.5)} 0`,}), styles?.rowHeading]
    const titleCss = [{fontWeight: 600}, styles?.title]

    const rowContentCss = [theme=>({
        color: 'text.secondary', 
        fontSize: '18px',
        paddingTop: theme.spacing(2),
        '&>div:first-of-type,&>span:first-of-type,&>p:first-of-type': {
            paddingTop: 0,
            marginTop: 0,
        },
        '&>*:last-child': {
            paddingBottom: 0,
            marginBottom: 0,
        },
        //remove inner margins and paddings of first and last inner elements. the content has its own padding.
        '&>div:first-of-type > div:first-of-type,span:first-of-type,p:first-of-type': {
            paddingTop: 0,
            marginTop: 0,
        },
        '&>div:last-of-type > div:last-of-type,span:last-of-type,p:last-of-type': {
            paddingBottom: 0,
            marginBottom: 0,
        },
    }), styles?.rowContent]

    const data = {
        title,
        rows: items.map((i)=>(
            {
                title: <Typography sx={rowHeadingCss}>{i.title}</Typography>, 
                content: <Box sx={rowContentCss} aria-hidden="true"><Markdown>{i.content}</Markdown></Box>
            }
        )),
        config
    }

    const renderRows = (rows) => {
        return rows.map(({title, content}, idx)=>{

            const itemId = elemId+'-'+idx

            return (
                <Box key={idx} sx={rowSx}>
                    <input type="checkbox" id={itemId} />
                    <label htmlFor={itemId} data-attached="false">{title} <KeyboardArrowDownIcon /></label>
                    {content}
                </Box>
            )
        })
    }
   
    if (typeof window === 'object') {
        const contentDivs = document.querySelectorAll(`#${elemId} label[data-attached=false] ~ div`)
        for (var i = 0; i < contentDivs.length; i++) {

            const div = contentDivs[i]
            const label = getSiblingByTagName(div, 'label')
            if (label) {
                label.setAttribute('data-attached', 'true')
                label.addEventListener('click', function(event) {
                    try {
                        const div = getSiblingByTagName(event.target, 'div')
                        div.setAttribute('aria-hidden', div.getAttribute('aria-hidden') == 'false')
                    } catch (error) {
                        console.error(error)
                    }
                });
            } else {
                console.error('error attaching click handler. label:', label)
            }
        }
    }

    const titleEffective = title || t('yoio:landingPage.faq.title')
    const rows = data.rows ? renderRows(data.rows) : undefined

    return (
        <>  
            {useLandingTitle?
                <>
                    {/* @ts-ignore */ }
                    <LandingSecondarySectionTitle 
                        text={titleEffective}
                        subtitleSecondary
                        titleCss={titleCss}
                        noMarginBottom={titleNoMarginBottom}
                        />
                </>
                :
                <Typography css={titleCss}>{titleEffective}</Typography>
            }
            <Box pb={6} sx={rootStyle} id={elemId}>
                {containerDisabled !== true ? 
                    <Container maxWidth="md">
                        {rows}
                    </Container>
                    :
                    rows
                }
            </Box>
            {/* @ts-ignore */ }
            {supportContact && <Typography textAlign="center" mb={6} color="text.secondary">{t('yoio:landingPage.faq.furtherQuestionsText', {channel: ''})} <LinkNormal href={`mailto:${supportContact}`} textSx={{fontWeight: 400}}>{supportContact}</LinkNormal></Typography>}
        </>
    )

}